import { Card } from '../components/Card/Card';
import PageHeader from '../components/PageHeader/PageHeader';
import React from 'react';
import { Trans } from 'react-i18next';
import { getDecodedToken } from 'utils/getDecodeToken';
import { paths } from '../constants/index';

const Security = () => {
  const decodedToken: any = getDecodedToken();
  const isAuthSrcBB = decodedToken && decodedToken.auth_src === 'BB';

  return (
    <div className="jg-background--white jg-bd@lg jg-bd--very-light-grey jg-space-pblg@lg">
      <div className="jg-space-psm jg-space-pllg@lg jg-space-ptlg@lg">
        <PageHeader>
          <h1 className="qa-page__heading">
            <Trans i18nKey="security.title">Security</Trans>
          </h1>
        </PageHeader>
      </div>

      <Card to={paths.Password} isAuthSrcBB={isAuthSrcBB}>
        <p className="jg-color--empress jg-text--medium-regular">
          <Trans i18nKey="tpasswordForm.changePassword">Change Password</Trans>
        </p>
      </Card>
    </div>
  );
};

export default Security;
